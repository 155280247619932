@import "../../variables";
@import "../../mixin";

.jobs-card-modal-wrapper {
    .title {
        text-align: center;
        color: #5a189a;
    }
    .filter-btns {
        button {
            width: 50%;
            &:first-of-type {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:last-of-type {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
    .top-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        .search-wrapper {
            display: flex;
            width: 80%;
            input {
                border-top-left-radius: 0.5rem;
                border-bottom-left-radius: 0.5rem;
                border-color: #ccc !important;
                box-shadow: none !important;
                margin-right: 1rem;
                &:focus {
                    border-color: $darkColor !important;
                }
            }
            button {
                width: 200px !important;
            }
        }
    }
    .ant-empty {
        min-height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }
    .cards-wrapper {
        display: grid;
        min-height: 200px;
        grid-column-gap: 21.5px;
        grid-row-gap: 20px;
        grid-template-columns: repeat(auto-fit, 100px);
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        .ant-card {
            background-color: rgba(224, 170, 255, 0.1);
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
}
