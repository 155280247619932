$darkGray: #5f5f5f;
$lightGray: #9ea5b1;
$veryLightGray: #f2f2f2;
$orderBackgroundColor: #eff0f5;
$diabledColor: #f5f5f5;
$green: #00ca00;
$loginBackground: linear-gradient(
  173deg,
  rgba(90, 24, 154, 1) 55%,
  rgba(138, 25, 148, 1) 89%
);
$lightColorWithOpacity: rgb(182 147 202); // Same light color but in rgb
$borderRadius: 0.5rem;

$darkColor: #5a189a;
$lightColor: rgba(224, 170, 255, 0.1);
$bgColor: #edf2fb;
$boxShadow: 0 0 0 2px rgb(90 24 154 / 20%);
$redColor: #ff0000;
$yellowColor: #ffbf00;
$blackColor: #333333;
