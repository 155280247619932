@import '../../variables';
@import '../../mixin';

.orders-actions {
  padding: 0.1rem;
  border-top: 1px solid $lightGray;
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 115px;
  .actions-wrap {
    display: grid;
    grid-template-columns: 40% 60%;
    .add-customer {
      height: 51px;
      display: flex;
      align-items: center;
      @include button-style();
      span {
        display: grid;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
      }
      .points {
        color: $darkColor;
        font-weight: 500;
        padding-left: 3px;
      }
    }
    .add-customer-large {
      height: 100px;
    }
    div {
      .update-action-button {
        height: 51px;
      }
      .price-update-color-ful {
        &:disabled {
          background: #f5f5f5;
          border-color: #d9d9d9;
        }
        background-color: $blackColor;
        border-color: $blackColor;
      }
    }
    .job-card {
      height: 45px;
      display: flex;
      align-items: center;
      @include button-style();
      span {
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
      }
    }
    div {
      margin-left: 0.5rem;
      button {
        width: 100%;
        height: 45px;
        &:not(.ant-btn-primary) {
          @include button-style();
        }
      }
      &.oil-actions {
        // display: grid;
        // grid-template-columns: 1fr 1fr 1fr;
        // column-gap: 0.5rem;
        // grid-template-columns: 1fr 1fr;
      }
    }
  }
  .payment-wrap {
    display: grid;
    grid-template-columns: calc(65% - 0.25rem) calc(35% - 0.25rem);
    column-gap: 0.5rem;
    margin-top: 0.5rem;
    .payment {
      height: 51px;
    }
    .payment-large {
      height: 101px;
    }
    .save-for-later {
      height: 51px;
      @include button-style();
      &:disabled {
        background: #f5f5f5;
        border-color: #d9d9d9;
      }
    }
    .save-for-later-large {
      height: 150px;
      margin-top: -50px;
    }
  }
}

.orders-dialpad-modal {
  width: 350px !important;
}

.job-card-modal {
  width: 1000px !important;
  .ant-modal-footer {
    padding: 0 !important;
    button {
      width: 100%;
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
      border: 0;
    }
  }
}
