// @import "~antd/dist/antd.css";
@import './antd.css';
@import './variables';

.full-screen-text-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin: 0 !important;
}

.ant-dropdown-menu {
  border-radius: $borderRadius;
}

.ant-select-selector {
  border-color: #fff !important;
}

.ant-select-dropdown {
  border-radius: $borderRadius !important;
}

.ant-modal-header {
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
}

.ant-modal-content {
  border-radius: $borderRadius;
}

.ant-spin-text {
  font-size: 25px;
  margin-top: 1.5rem;
  font-weight: bold;
}

.ant-table-row {
  td {
    padding: 0.5rem !important;
    &:nth-child(2) {
      text-transform: capitalize !important;
    }
    &:not(:last-child) {
      min-width: 210px !important;
    }
    &:last-child {
      min-height: 39px;
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.3rem;
    }
  }
}
.ant-table-row:hover {
  cursor: pointer;
  td {
    background-color: $lightColor !important;
  }
}

.ant-modal-confirm-btns {
  display: flex;
  float: none !important;
  justify-content: space-between;
  button {
    height: 40px;
    width: 5rem;
  }
}

@media print {
  html,
  body {
    height: max-content;
    page-break-inside: avoid;
  }
}

input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
