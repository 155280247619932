@import '../../variables';

.orders-queue {
  padding: 0.5rem;
  display: grid;
  row-gap: 0.5rem;
  overflow: auto;
  height: calc(100% - 115px);
  .order-name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .order {
    cursor: pointer;
    width: 100%;
    padding: 0.3rem 0.5rem;
    display: grid;
    grid-template-columns: auto 75px;
    align-items: center;

    &.ingredient {
      padding-left: 1.5rem !important;
      font-style: italic !important;
      div {
        font-weight: normal !important;
      }
    }

    .title-and-description {
      .title {
        font-weight: 500;
      }
      .description {
        padding-left: 0.5rem;
        .quantity {
          font-weight: 500;
        }
      }
      .orderline-tax {
        padding-left: 0.5rem;
        .tax-text {
          font-weight: 500;
        }
      }
      .react_instructions_list {
        padding-left: 0.5rem;
        .instructions {
          color: $darkColor;
          font-weight: 500;
        }
      }
      .orderline-note {
        padding-left: 0.5rem;
        .note-icon {
          color: $darkColor;
          font-weight: 500;
        }
      }
    }
    .total {
      font-size: 16px;
      float: right;
      text-align: end;
    }
  }
  .selected {
    background-color: $orderBackgroundColor;
    border-radius: $borderRadius;
  }
  .is-redeemed {
    color: $yellowColor;
  }
  .drive-thru-only {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-top: 0 !important;
  }
  .total-price-wrapper {
    border-top: 2px solid $darkColor;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    float: right;
    margin-right: 1rem;
    div {
      font-size: 15px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      span {
        margin-left: 1rem;
        color: $darkColor;
        font-weight: bold;
      }
    }
  }
}

.center {
  place-items: center;
}

.delete-order-line-modal {
  .delete-order-line-modal-content-wrapper {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 20% 75%;
    place-items: center;
    column-gap: 5%;
    .anticon-exclamation-circle {
      color: #d9363e;
      font-size: 2.5rem;
    }
  }
  .button-wrapper {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    button {
      height: 40px;
      width: 6rem;
    }
  }
}
